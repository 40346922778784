import React from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { InputText } from "../../../components/elements/input/textInput/InputText";
import { ChatSuggestions } from "./ChatSuggestions";

type Props = {
  createNewChat: () => void;
  mode: string;
  sendUserMessage: (text: string) => void;
  userInput: string;
  setInput: React.Dispatch<React.SetStateAction<string>>;
  isLoadingAnswerFromAI: boolean;
  didChatStart: boolean;
};

export const WaspAIInput = (props: Props) => {
  const {
    createNewChat,
    mode,
    sendUserMessage,
    userInput,
    setInput,
    isLoadingAnswerFromAI,
    didChatStart,
  } = props;

  return (
    <Flex w100 style={{ height: "25%" }} column>
      <div
        className="w-100 h-100 d-flex justify-content-center align-items-center"
        data-tut="wasp-ai-input"
      >
        <Flex gap="16px" w100 h100 justify="center" align="center" column>
          {!didChatStart && (
            <ChatSuggestions sendUserMessage={sendUserMessage} />
          )}
          <Flex gap="16px" h100 justify="center" align="center">
            <IconButton
              size="medium"
              label="New chat"
              iconName={"write"}
              onClick={() => {
                createNewChat();
              }}
            />
            <InputText
              disabled={isLoadingAnswerFromAI}
              placeholder="Ask WASP AI ..."
              width={mode === "Full Screen" ? "1000px" : "500px"}
              value={userInput}
              onChange={(e) => {
                setInput(e.target.value);
              }}
              onEnterPressed={() => {
                sendUserMessage(userInput);
              }}
            />
            <IconButton
              disabled={isLoadingAnswerFromAI}
              label="Send message "
              iconName={"send"}
              onClick={() => {
                sendUserMessage(userInput);
              }}
            />
          </Flex>
        </Flex>
      </div>
    </Flex>
  );
};
