import { ReactNode, useContext } from "react";
import { SeparatorVertical } from "../separators/SeparatorVertical";
import { BodyRegular, LabelRegular } from "../typography/Typography";
import { Flex } from "../../layouts/flex/Flex";
import { light } from "../../../shared/theme";
import { ThemeContext } from "styled-components";

type SectionProps = {
  title: ReactNode;
  children: JSX.Element | JSX.Element[];
  noSeparator?: Boolean;
  headerStyle?: "label" | "body";
  width?: string;
  noOverflow?: boolean;
  isReport?: boolean;
};

export const Section = (props: SectionProps) => {
  const {
    title,
    children,
    noSeparator = false,
    headerStyle = "label",
    isReport = false,
  } = props;

  const theme = useContext(ThemeContext);

  return (
    <Flex
      w100
      justify="between"
      align="center"
      style={{ overflow: "visible", padding: "4px" }}
    >
      <Flex column gap="8px" w100 style={{ overflow: "visible" }}>
        {headerStyle === "label" ? (
          <LabelRegular
            className="text-truncate w-100"
            style={{ color: isReport ? light.black700 : theme.black700 }}
          >
            {title}
          </LabelRegular>
        ) : (
          <BodyRegular
            className="text-truncate w-100"
            style={{ color: isReport ? light.black700 : theme.black700 }}
          >
            {title}
          </BodyRegular>
        )}
        <Flex align="center" gap="8px">
          {children}
        </Flex>
      </Flex>
      {!noSeparator && <SeparatorVertical style={{ height: "32px" }} />}
    </Flex>
  );
};
