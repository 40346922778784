import { useContext } from "react";
import { Tooltip } from "../../../../components/elements/tooltip/Tooltip";
import {
  BodyRegular,
  BodyRegularHover,
} from "../../../../components/elements/typography/Typography";
import { Project } from "../../../../types/Project";
import { Section } from "../../../../components/elements/section/Section";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { Icon } from "../../../../components/elements/icon/Icon";
import { ThemeContext } from "styled-components";

type Props = {
  isAutomated?: boolean;
  project: Project | undefined | null;
  noSeparator?: boolean;
  width?: string;
  onClickProject?: (project: Project) => void;
};

export const DiscoverOnItem = (props: Props) => {
  const {
    project,
    noSeparator = false,
    isAutomated = false,
    onClickProject,
  } = props;
  const theme = useContext(ThemeContext);
  return (
    <Section title="Discovered on" noSeparator={noSeparator}>
      <Flex
        w100
        style={{ overflow: "hidden" }}
        onClick={() => project && onClickProject && onClickProject(project)}
      >
        <Tooltip
          isTextTruncate
          placement="bottom"
          content={
            isAutomated || project?.type?.toLowerCase() === "asm"
              ? "ASM"
              : project?.name || "N/A"
          }
        >
          {isAutomated || project?.type?.toLowerCase() === "asm" ? (
            <Flex gap="8px">
              <Icon name="wasp" size={24} color={theme.primary} />
              <BodyRegular>Automated</BodyRegular>
            </Flex>
          ) : !!onClickProject ? (
            <BodyRegularHover className="text-truncate">
              {project?.name || "N/A"}
            </BodyRegularHover>
          ) : (
            <BodyRegular className="text-truncate">
              {project?.name || "N/A"}
            </BodyRegular>
          )}
        </Tooltip>
      </Flex>
    </Section>
  );
};
