import { Dispatch, MutableRefObject, SetStateAction, useContext } from "react";
import { FormError } from "../../../../components/elements/FormsElements/FormError";
import { Box } from "../../../../components/elements/box/Box";
import { Icon } from "../../../../components/elements/icon/Icon";
import { InputText } from "../../../../components/elements/input/textInput/InputText";
import {
  RequiredField,
  OptionalField,
} from "../../../../components/elements/requiredField/RequiredField";
import {
  HeaderSecondary,
  LabelRegular,
} from "../../../../components/elements/typography/Typography";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import {
  FormErrors,
  FormModeState,
  onRichTextChangedHandler,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { useParams } from "react-router";
import {
  useApiCreateFindingImages,
  useApiUpdateFinding,
} from "../../../../hooks/queries/findingContext";
import { RichTextEditorWASP } from "../../../../components/elements/richTextEditor/RichTextEditor";
import { uploadFindingTextAndImages } from "../ImageUtils";
import useToastContext from "../../../../hooks/toastHook";

type Props = {
  formMode: FormModeState;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  updateFindingData?: Finding;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
  clearTrigger: MutableRefObject<boolean>;
  valueTrigger: MutableRefObject<boolean>;
  formErrors: FormErrors;
  setFormErrors: (formErrors: FormErrors) => void;
};

export const BasicInformation = (props: Props) => {
  const {
    formMode,
    createFindingData,
    setCreateFinding,
    updateFindingData,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
    clearTrigger,
    valueTrigger,
    formErrors,
    setFormErrors,
  } = props;

  const theme = useContext(ThemeContext);
  const addToast = useToastContext();

  const { id: updateFindingId } = useParams();
  const { mutate: createImage } = useApiCreateFindingImages();
  const { mutate: updateFinding } = useApiUpdateFinding({
    "admin-mode": true,
  });

  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Box style={{ width: "50%" }}>
      <Flex column gap="32px">
        <Flex gap="8px">
          <Icon name="info" size={30} color={theme.primary} />{" "}
          <HeaderSecondary>Basic Information</HeaderSecondary>
        </Flex>
        <Flex column gap="8px" data-testid="title-field">
          <Flex justify="between">
            <Flex>
              <LabelRegular>Title</LabelRegular>
              <RequiredField />
            </Flex>
          </Flex>
          <InputText
            placeholder="XSS vulnerability..."
            width="100%"
            queryStatus={changedField === "title" ? queryStatus : undefined}
            disabled={queryStatus && queryStatus !== "idle"}
            dataTestId="title-input"
            isRequired
            saveChangesMode={formMode === FormModeState.Update}
            onEnterPressed={() => {
              updateInPlace({ title: editableUpdateFindingData?.title });
            }}
            onSave={() => {
              updateInPlace({ title: editableUpdateFindingData?.title });
            }}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                title: updateFindingData?.title,
              }));
            }}
            initValue={updateFindingData?.title}
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.title
                  ? editableUpdateFindingData.title
                  : ""
                : createFindingData.title
                  ? createFindingData.title
                  : ""
            }
            onChange={(e) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }
              if (formMode === FormModeState.Create) {
                setCreateFinding((prev) => ({
                  ...prev,
                  title: e.target.value,
                }));
              }

              let errors = { ...formErrors };
              errors.title = e.target.value === "" ? ["Title is required"] : [];
              setFormErrors(errors);
            }}
            isError={!!formErrors?.title?.length}
          />
          {formErrors?.title?.map((err) => <FormError errorMessage={err} />)}
        </Flex>

        <Flex column gap="8px">
          <Flex justify="between">
            <Flex>
              <LabelRegular>One-liner summary</LabelRegular>
              <OptionalField />
            </Flex>
          </Flex>
          <InputText
            placeholder="Write short summary..."
            width="100%"
            disabled={queryStatus && queryStatus !== "idle"}
            queryStatus={changedField === "summary" ? queryStatus : undefined}
            saveChangesMode={formMode === FormModeState.Update}
            onEnterPressed={() => {
              updateInPlace({ summary: editableUpdateFindingData?.summary });
            }}
            onSave={() => {
              updateInPlace({ summary: editableUpdateFindingData?.summary });
            }}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                summary: updateFindingData?.summary,
              }));
            }}
            initValue={updateFindingData?.summary || ""}
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.summary
                  ? editableUpdateFindingData.summary
                  : ""
                : createFindingData.summary
                  ? createFindingData.summary
                  : ""
            }
            onChange={(e) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  summary: e.target.value,
                }));
              }
              if (formMode === FormModeState.Create) {
                setCreateFinding((prev) => ({
                  ...prev,
                  summary: e.target.value,
                }));
              }
            }}
          />
        </Flex>

        <Flex column>
          <Flex justify="between">
            <Flex>
              <LabelRegular>Description</LabelRegular>
              <OptionalField />
            </Flex>
          </Flex>
          <RichTextEditorWASP
            isHidden
            placeholderText="Provide a detailed description of the vulnerability discovered..."
            theme={theme}
            valueTrigger={valueTrigger}
            clearTrigger={clearTrigger}
            saveChangesMode={formMode === FormModeState.Update}
            value={
              formMode === FormModeState.Update
                ? editableUpdateFindingData?.description_wasp
                : createFindingData.description_wasp
            }
            onChange={(html: string, markdown: string) => {
              onRichTextChangedHandler(
                html,
                "description_wasp",
                markdown,
                "description",
                formMode === FormModeState.Create
                  ? setCreateFinding
                  : setEditableUpdateFinding
              );
            }}
            isSaveDisabled={
              updateFindingData?.description_wasp ===
                editableUpdateFindingData?.description_wasp ||
              queryStatus !== "idle"
            }
            onSave={async () => {
              uploadFindingTextAndImages(
                parseInt(updateFindingId ? updateFindingId : "0"),
                editableUpdateFindingData?.description_wasp || "",
                editableUpdateFindingData?.attack_details_wasp || "",
                editableUpdateFindingData?.mitigation_wasp || "",
                editableUpdateFindingData?.description || "",
                editableUpdateFindingData?.attack_details || "",
                editableUpdateFindingData?.mitigation || "",
                editableUpdateFindingData?.description_html || "",
                editableUpdateFindingData?.attack_details_html || "",
                editableUpdateFindingData?.mitigation_html || "",
                createImage,
                updateFinding,
                undefined,
                addToast
              );
            }}
            onCancel={() => {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                description_wasp: updateFindingData?.description_wasp,
                description: updateFindingData?.description,
              }));
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
