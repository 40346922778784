import { useParams } from "react-router";
import { useApiMe } from "../../../../hooks/queries/meContext";
import { useApiScanners } from "../../../../hooks/queries/scannersContext";
import {
  FormModeState,
  useUpdateFindingInPlace,
} from "../../../../shared/formUtils";
import { capitalize } from "../../../../shared/helper";
import { AdminFindingEdit, Finding } from "../../../../types/Finding";
import { Dispatch, SetStateAction, useState } from "react";
import { Flex } from "../../../../components/layouts/flex/Flex";
import { LabelRegular } from "../../../../components/elements/typography/Typography";
import { RequiredField } from "../../../../components/elements/requiredField/RequiredField";
import { Switch } from "../../../../components/elements/switch/Switch";
import { Dropdown } from "../../../../components/elements/dropdowns/Dropdown";
import { SeparatorVertical } from "../../../../components/elements/separators/SeparatorVertical";

type Props = {
  formMode: FormModeState;
  createFindingData: AdminFindingEdit;
  setCreateFinding: Dispatch<SetStateAction<AdminFindingEdit>>;
  setUpdateFinding: Dispatch<SetStateAction<Finding | undefined>>;
  editableUpdateFindingData: AdminFindingEdit | null;
  setEditableUpdateFinding: Dispatch<SetStateAction<AdminFindingEdit | null>>;
};

export const IsAutomatedAndScanner = (props: Props) => {
  const {
    formMode,
    createFindingData,
    setCreateFinding,
    setUpdateFinding,
    editableUpdateFindingData,
    setEditableUpdateFinding,
  } = props;

  const { data: me } = useApiMe();
  const { id: updateFindingId } = useParams();

  //  Scanners Options
  const { data: scanners } = useApiScanners(me?.customer?.id);
  let scannersOptions =
    scanners?.map((s) => ({ label: capitalize(s.name), value: s.name })) || [];
  scannersOptions.push({ value: "wasp", label: "WASP" });

  const [showScannerSelect, setShowScannerSelect] = useState<boolean>(
    formMode === FormModeState.Update
      ? !!editableUpdateFindingData?.is_automated
      : !!createFindingData?.is_automated
  );

  const { updateInPlace, queryStatus, changedField } = useUpdateFindingInPlace(
    parseInt(updateFindingId ? updateFindingId : "0"),
    setUpdateFinding
  );

  return (
    <Flex gap="12px" align="center" style={{ height: "32px" }}>
      <Flex gap="8px" align="center">
        <Flex>
          <LabelRegular>Found by ASM?</LabelRegular>
        </Flex>
        <Switch
          checked={
            formMode === FormModeState.Update
              ? !!editableUpdateFindingData?.is_automated
              : !!createFindingData?.is_automated
          }
          onChange={(checked) => {
            if (formMode === FormModeState.Update) {
              setEditableUpdateFinding((prev) => ({
                ...prev,
                is_automated: checked,
                scanner_name: "wasp",
              }));
              updateInPlace({ is_automated: checked });
            }
            if (formMode === FormModeState.Create) {
              setCreateFinding((prev) => ({
                ...prev,
                is_automated: checked,
                scanner_name: "wasp",
              }));
            }
            setShowScannerSelect(checked);
          }}
        />
      </Flex>
      {showScannerSelect && (
        <Flex gap="8px" align="center">
          <SeparatorVertical height="24px" />
          <Flex>
            <LabelRegular>Scanner</LabelRegular>
            <RequiredField />
          </Flex>
          <Dropdown
            placeholder="Select Scanner"
            variant="border"
            options={scannersOptions}
            value={scannersOptions.find((opt) =>
              formMode === FormModeState.Update
                ? opt.value === editableUpdateFindingData?.scanner_name
                : opt.value === createFindingData?.scanner_name
            )}
            onChange={(opt) => {
              if (formMode === FormModeState.Update) {
                setEditableUpdateFinding((prev) => ({
                  ...prev,
                  scanner_name: `${opt?.value}`,
                }));
                updateInPlace({ scanner_name: opt?.value });
              }
              if (formMode === FormModeState.Create) {
                setCreateFinding((prev) => ({
                  ...prev,
                  scanner_name: `${opt?.value}`,
                }));
              }
            }}
            disabled={queryStatus !== "idle"}
            queryStatus={
              changedField === "scanner_name" ? queryStatus : undefined
            }
          />
        </Flex>
      )}
    </Flex>
  );
};
