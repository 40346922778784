import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { Option } from "../components/elements/dropdowns/Dropdown";
import { useApiUpdateFinding } from "../hooks/queries/findingContext";
import useToastContext from "../hooks/toastHook";
import { Me } from "../types/Me";
import { AdminFindingEdit, Finding, emptyFinding } from "../types/Finding";
import { Product } from "../types/Product";
import { Customer } from "../types/Customer";

export enum FormSubmissionState {
  Initial = "INITIAL",
  Loading = "LOADING",
  Successful = "SUCCESSFUL",
  FailedSubmission = "FAILED_SUB",
  FailedValidation = "FAILED_VAL",
  AreYouSure = "ARE_YOU_SURE",
}

export enum FormModeState {
  Update = "UPDATE",
  Create = "LOADING",
}

export type FormErrors = { [key: string]: string[] };
export type FormValues = { [key: string]: any };

type waspObj =
  | {
      id: number;
      name: string;
    }
  | {
      id: number;
      user: Me;
    };

export const mapToOptions = (map: {
  [key: string | number]: any;
}): Option[] => {
  return Object.keys(map).map((key) => {
    return { value: key, label: map[key] };
  });
};

export const customersPriorityTierOptions = [
  { label: "All tiers", value: "all" },
  { label: "Top", value: "top" },
  { label: "Medium", value: "medium" },
  { label: "Low", value: "low" },
  { label: "No tier", value: "no tier" },
];

export const objectToOption = (
  waspObj: waspObj,
  includeEmail: boolean = false
): Option => {
  if (!waspObj) return { value: -1, label: "" };

  if ("name" in waspObj) {
    return { value: waspObj.id, label: waspObj.name };
  } else if ("user" in waspObj) {
    return includeEmail
      ? {
          value: waspObj.id,
          label: `${waspObj.user.name} (${waspObj.user.email})`,
        }
      : { value: waspObj.id, label: waspObj.user.name };
  }
  return { value: -1, label: "" };
};

export const objectsToOptions = (
  objs: waspObj[],
  includeEmail: boolean = false
): Option[] => {
  return objs?.map((c: waspObj) => objectToOption(c, includeEmail));
};

/**
 * Transforms a list of products into options with associated customer names.
 *
 * @param {Product[]} products - The list of products to convert to options.
 * @param {Customer[]} customers - The list of customers, each with an `id` and `name`,
 * used to associate each product with its customer.
 * @returns {Option[]} An array of options, where each option includes a product ID and
 * a display name in the format "(Customer Name) Product Name".
 *
 * The function maps over the `products` array, finding the corresponding customer
 * by matching the `product.customer` ID with a customer `id` from the `customers` list.
 * Each product is then formatted as an `Option` object using `objectToOption`.
 */
export const productsToOptionsWithCustomers = (
  products: Product[],
  customers: Customer[]
): Option[] => {
  return products?.map((p) =>
    objectToOption({
      id: p.id,
      name: `(${customers?.find((customer) => p.customer === customer.id)?.name}) ${p.name}`,
    })
  );
};

export const arraysToOptions = (
  arrayLabels: string[],
  arrayValues: number[]
) => {
  if (arrayLabels.length > 0 && arrayValues.length > 0) {
    let options: Option[] = [];
    let minLength = Math.min(arrayLabels.length, arrayValues.length);
    for (let i = 0; i < minLength; i++) {
      options.push({ value: arrayValues[i], label: arrayLabels[i] });
    }
    return options;
  } else {
    return null;
  }
};

export const useUpdateFindingInPlace = (
  id: number,
  setFinding: Dispatch<SetStateAction<Finding | undefined>>
): {
  updateInPlace: (changes: { [key: string]: any }) => void;
  queryStatus: "error" | "idle" | "loading" | "success";
  changedField: string;
} => {
  const {
    mutate: updateFinding,
    status: queryStatus,
    reset,
  } = useApiUpdateFinding({
    "admin-mode": true,
  });
  const addToast = useToastContext();
  const [changedField, setChangedField] = useState<string>("");

  const updateInPlace = useCallback(
    (changes: { [key: string]: any }) => {
      // The function can be called once at a time
      const fields = Object.keys(changes);
      console.log({ fields });
      if (queryStatus !== "idle" || !fields.length) return;

      // If we have multiple fields
      // like affected assets where we also have the display fields
      // we use the first field name
      setChangedField(fields[0]);

      updateFinding({
        id: id,
        ...changes,
        onSuccessCallback: (finding) => {
          if (setFinding) setFinding({ ...finding });
          addToast({
            message: `Finding's ${changedField} updated successfully!`,
            type: "success",
          });

          // Reset the query status and changed field
          setTimeout(() => {
            reset();
            setChangedField("");
          }, 2000);
        },
        onErrorCallback: (error) => {
          addToast({
            message: `Failed to update! Error: ${error}`,
            type: "error",
          });
          setTimeout(() => {
            reset();
            setChangedField("");
          }, 1000);
        },
      });
    },
    // eslint-disable-next-line
    [updateFinding, addToast]
  );
  return { updateInPlace, queryStatus, changedField };
};

export const onRichTextChangedHandler = (
  htmlValue: string,
  htmlField: string,
  markdownValue: string,
  markdownField: string,
  updateState: (updateFunction: (prevState: any) => any) => void
) => {
  updateState((prev) => ({
    ...prev,
    [htmlField]: htmlValue,
    [markdownField]: markdownValue,
  }));
};

export const getOptionFromObjects = (
  objs: waspObj[],
  value: string | number
): Option => {
  const options = objectsToOptions(objs);
  return options.filter((option) => {
    return option.value === value;
  })[0];
};

export const getOptionFromKeyValuePairs = (
  obj: { [key: string | number]: any },
  value: string | number
): Option => {
  const label = obj[value];
  return { label: label, value: value };
};

type DiffResult = {
  changed: string[];
};

function deepEqual(obj1: any, obj2: any) {
  if (obj1 === obj2) return true;

  if (typeof obj1 === "string" && obj1 === "[]") obj1 = [];
  if (typeof obj2 === "string" && obj2 === "[]") obj2 = [];

  if (
    typeof obj1 !== "object" ||
    obj1 === null ||
    typeof obj2 !== "object" ||
    obj2 === null
  ) {
    return false;
  }

  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) return false;
    // Sort arrays before comparing if order doesn't matter
    const sortedObj1 = [...obj1].sort();
    const sortedObj2 = [...obj2].sort();
    for (let i = 0; i < sortedObj1.length; i++) {
      if (!deepEqual(sortedObj1[i], sortedObj2[i])) {
        return false;
      }
    }
    return true;
  }

  // Handle empty array cases
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length === 0 && obj2.length === 0) return true;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) return false;

  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}
export function diffChanges(obj1: Finding, obj2: Finding): DiffResult {
  const changed: any = [];

  for (const key in obj2) {
    if (key === "updated_at") continue;
    if (!deepEqual(obj1[key as keyof Finding], obj2[key as keyof Finding])) {
      changed.push(key as keyof Finding);
    }
  }
  return { changed };
}

export function getEmptyCustomerInsertedFinding(me: Me): AdminFindingEdit {
  return {
    ...emptyFinding,
    customer: me.customer.id,
    op_jira_assignee: {
      jira_id: "",
      display_name: me.first_name + " " + me.last_name,
      email: me.email,
      avatar_url: me.avatar_url ? me.avatar_url : "",
    },
    is_pending: false,
    source: "CUSTOMER_INSERTED",
  };
}

export function getEmptyAssigneeInsertedFinding(me: Me): AdminFindingEdit {
  return {
    ...emptyFinding,
    op_jira_assignee: {
      jira_id: "",
      display_name: me.first_name + " " + me.last_name,
      email: me.email,
      avatar_url: me.avatar_url ? me.avatar_url : "",
    },
    is_pending: true,
    source: "WASP_TEAM",
  };
}
