import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  DEFAULT_STALE_TIME,
  invalidateApiQueries,
  removeItemFromContext,
} from "./utils";
import {
  getItems,
  createItem,
  updateItem,
  deleteItem,
  getSingleItem,
} from "./sdk";
import { Product } from "../../types/Product";
import { bitAfterNow } from "../../shared/helper";

const key = "products";

// All possible attributes that can pass to API call
export interface ProductParams {
  name?: string;
  description?: string;
  customer?: number;
  accounts_allowed?: number[];
  onSuccessCallback?: (product: Product) => void;
  onErrorCallback?: (error: Error) => void;
}

export interface ProductContext {
  productId: number;
  productData: ProductParams;
  onSuccessCallback?: (product: Product) => void;
  onErrorCallback?: (error: Error) => void;
}

export const useApiProducts = (filters?: { [key: string]: any }) =>
  useQuery<Product[], Error>({
    queryKey: [key, filters],
    keepPreviousData: true,
    placeholderData: [],
    enabled: true,
    queryFn: async (): Promise<Product[]> => getItems(key, filters),
  });

export const useApiSingleProduct = (id: number) =>
  useQuery<Product | undefined, Error>({
    queryKey: [key, { id }],
    placeholderData: undefined,
    staleTime: DEFAULT_STALE_TIME,
    initialDataUpdatedAt: bitAfterNow(),
    enabled: !!id,
    queryFn: async (): Promise<Product | undefined> =>
      getSingleItem(key, `${id}`) as Promise<Product | undefined>,
  });

export const useApiCreateProduct = () => {
  const queryClient = useQueryClient();
  return useMutation<Product, Error, ProductParams>({
    mutationKey: [key],
    mutationFn: async (newProduct): Promise<Product> =>
      await createItem(key, newProduct),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: () => invalidateApiQueries([key], queryClient),

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiUpdateProduct = ({ id }: { id?: number }) => {
  const queryClient = useQueryClient();
  return useMutation<Product, Error, ProductContext>({
    mutationKey: [key, { id }],
    mutationFn: async ({
      productId,
      productData,
    }: ProductContext): Promise<Product> =>
      await updateItem(key, productId, productData),
    onSuccess: (data, { onSuccessCallback }) =>
      onSuccessCallback && onSuccessCallback(data),
    onSettled: (data, error, variables) => {
      invalidateApiQueries([key], queryClient);
      if (variables.productData.accounts_allowed)
        invalidateApiQueries(["accounts"], queryClient);
    },

    onError: (error: Error, { onErrorCallback }) => {
      onErrorCallback && onErrorCallback(error);
    },
  });
};

export const useApiDeleteProduct = () => {
  const queryClient = useQueryClient();
  return useMutation<Product, Error, Product>({
    mutationKey: [key],
    mutationFn: async (product): Promise<any> =>
      await deleteItem(key, product, product.id),
    onSuccess: (data, variables) =>
      removeItemFromContext({
        data,
        queryKey: [key],
        queryClient,
      }),
    onSettled: () => invalidateApiQueries([key], queryClient),
  });
};
