import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { ThemeContext } from "styled-components";
import { Box } from "../../../components/elements/box/Box";
import {
  BodyRegular,
  HeaderMain,
  LabelBold,
  LabelMedium,
} from "../../../components/elements/typography/Typography";
import { useApiSingleFinding } from "../../../hooks/queries/findingContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import { FindingBadges } from "../findingPane/FindingBadges";
import { BreadcrumbButton } from "../../../components/elements/button/breadcrumb/BreadcrumbButton";
import { AffectedAssetsPane } from "./AffectedAssetsPane";
import { FindingsPageNavButton } from "./FindingsPageNavButton";
import { LinkButton } from "../../../components/elements/button/link/LinkButton";
import { Icon } from "../../../components/elements/icon/Icon";
import { TopBoxes } from "./TopBoxes";
import { TopBoxesSkeleton } from "./TopBoxesSkeleton";
import { MiddleBox } from "./MiddleBox";
import { MiddleBoxSkeleton } from "./MiddleBoxSkeleton";
import { BottomBox } from "./BottomBox";
import { BottomBoxSkeleton } from "./BottomBoxSkeleton";
import { ImageModal } from "../../../components/composed/imageModal/ImageModal";
import { Link, useSearchParams } from "react-router-dom";
import { SkeletonLoading } from "../../../components/elements/loading/SkeletonLoading";
import { useTrackPage } from "../../../hooks/trackingHooks";
import { PushFindingJira } from "../pushFindingJira/PushFindingJira";
import { FindingHistory } from "../findingHistoryAndComments/FindingHistory";
import { SecondaryButton } from "../../../components/elements/button/secondary/SecondaryButton";
import { NoDataAvailable } from "../../insights/NoDataAvailable";
import { useLicense } from "../../../licensing/LicenseManager";
import { FindingsTrialLimitation } from "../../../components/composed/trialLimitationsMessages/FindingsTrialLimitation";
import { Flex } from "../../../components/layouts/flex/Flex";
import { useScreenWidth } from "../../../hooks/utilsHooks";
import {
  SCREEN_MEDIUM_LAPTOP_WIDTH,
  SCREEN_MOBILE_WIDTH,
} from "../../../shared/consts";
import FindingDetailsMobile from "./FindingDetailsMobile";
import { FindingsExpired } from "../../../licensing/sub-components/FindingsExpired";
import { Badge } from "../../../components/elements/badge/Badge";
import { useIsSuperuser } from "../../../hooks/useIsSuperuser";
import { RelatedFindings } from "./RelatedFindings";
import AlertBanner from "../../../components/elements/toastTypes/AlertBanner";
import { ProjectPane } from "../../projects/projectPane/ProjectPane";
import { Project } from "../../../types/Project";
import { AssetPane } from "../../assets/assetsPane/AssetPane";

export const FindingDetails = () => {
  const { id: findingId } = useParams();
  useTrackPage("Findings - Details", { findingId: findingId || "" });
  const { licenseType } = useLicense();
  const screenWidth = useScreenWidth();
  const isMobile = screenWidth < SCREEN_MOBILE_WIDTH;
  const isSmallScreen = screenWidth < SCREEN_MEDIUM_LAPTOP_WIDTH;
  const theme = useContext(ThemeContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filterBase64 = searchParams.get("filters");
  const isSuperuser = useIsSuperuser();

  const { data: me } = useApiMe();

  const {
    data: finding,
    isFetching,
    isRefetching,
    status: findingQueryStatus,
  } = useApiSingleFinding(
    parseInt(findingId || "0"),
    searchParams.get("admin-mode") === "true"
  );

  const isTrialLimited =
    licenseType === "trial" &&
    finding?.overall_risk &&
    finding?.overall_risk >= 3;

  const [showImageModal, setShowImageModal] = useState(false);
  const [showAffectedAssetsPane, setShowAffectedAssetsPane] = useState(false);

  const [showProject, setShowProject] = useState<Project | undefined>();
  const [showAsset, setShowAsset] = useState<number>(0);

  const [clickedSource, setClickedSource] = useState("");

  const registerOnClickImageEvents = () => {
    const elements = document.getElementsByClassName("wasp-image-big");
    if (elements.length > 0) {
      Array.from(elements).forEach((elm) => {
        if (elm instanceof HTMLImageElement) {
          elm.onclick = () => {
            setClickedSource(elm.src);
            setShowImageModal(true);
          };
        }
      });
    }
  };

  setTimeout(registerOnClickImageEvents, 1000);

  const hasProjectMapping = !!me?.customer?.jira_projects_mapping?.filter(
    (p) => !p.auto_send && p.enabled
  ).length;
  const hasAutoSendMapping = !!me?.customer?.jira_projects_mapping?.some(
    (p) => p.auto_send && p.enabled
  );

  // Sync right flex to center flex height
  const boxesFlexRef = useRef<HTMLDivElement | null>(null);
  const [boxesHeight, setBoxesHeight] = useState<string>("");
  useEffect(() => {
    if (!!boxesFlexRef?.current) {
      const elementHeight = boxesFlexRef.current.clientHeight;
      setBoxesHeight(`${elementHeight}px`);
    }
  }, []);

  const backUrl = filterBase64
    ? `/findings?filters=${filterBase64}`
    : "/findings";

  if (!findingId) {
    navigate(backUrl);
    return <div>Id is undefined</div>;
  }

  if (isMobile) return <FindingDetailsMobile finding={finding} />;

  return findingQueryStatus === "error" ? (
    <NoDataAvailable />
  ) : (
    <>
      {!isRefetching &&
        !isFetching &&
        me?.customer.id !== finding?.customer && (
          <Flex w100 style={{ marginBottom: "16px" }}>
            <AlertBanner
              message={
                <>
                  <LabelBold>Attention!</LabelBold>
                  <LabelMedium>
                    You are viewing a finding of a different customer from the
                    one you are assigned to.
                  </LabelMedium>
                </>
              }
              type="warning"
            />
          </Flex>
        )}
      <div className="d-flex align-items-center w-100">
        <Flex align="center" gap="8px">
          <BreadcrumbButton
            onClick={() => navigate(backUrl)}
            label={"Findings"}
            width="65px"
          />
          <Icon name="chevronRight" size={16} color={theme.black600} />
          <BodyRegular data-testid="ticket-num">
            Ticket #{finding?.id}
          </BodyRegular>
        </Flex>

        <FindingsPageNavButton
          isFetching={isFetching}
          findingId={findingId}
          finding={finding}
          currentFiltersBase64={filterBase64 || ""}
        />
      </div>
      <div className="d-flex flex-column gap-24 mb-2">
        <div className="d-flex justify-content-between align-items-end">
          <div
            className="d-flex flex-column justify-content-center gap-8"
            style={{ maxWidth: "60%" }}
          >
            {" "}
            <Flex gap="8px" align="center">
              <HeaderMain>
                {isFetching ? (
                  <SkeletonLoading height="58px" width="600px" />
                ) : (
                  finding?.title
                )}
              </HeaderMain>
              {(isSuperuser ||
                (me?.customer.is_multi_tenant &&
                  finding?.source === "CUSTOMER_INSERTED")) && (
                <Flex align="center" gap="8px">
                  <LinkButton
                    size="medium"
                    label="Edit Finding"
                    iconName="edit"
                    onClick={() => {
                      navigate(`/research/finding/create/${findingId}`);
                    }}
                  />
                  {finding?.retest_not_found && (
                    <Badge
                      color={theme.redPrimary}
                      backgroundColor={theme.red50}
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignContent: "center",
                        justifyItems: "center",
                        textAlign: "center",
                        height: "100%",
                      }}
                    >
                      Retest Not Found
                    </Badge>
                  )}
                  {finding?.is_pending && (
                    <Badge
                      color={theme.redPrimary}
                      backgroundColor={theme.red50}
                    >
                      Pending
                    </Badge>
                  )}
                </Flex>
              )}
            </Flex>
            <div className="d-flex align-items-center ">
              <FindingBadges finding={finding} />
            </div>
          </div>
          {!isTrialLimited && (
            <div className="d-flex align-items-center gap-16 justify-content-end">
              {isSuperuser && (
                <LinkButton
                  size="medium"
                  label="Go to OP Jira"
                  iconName="export"
                  disabled={!finding?.jira_key}
                  onClick={() => {
                    window
                      .open(
                        `https://op-innovate.atlassian.net/browse/${finding?.jira_key}`,
                        "_blank"
                      )
                      ?.focus();
                  }}
                />
              )}
              <Link
                to={`/findings/${finding?.id}/pdf`}
                style={{ textDecoration: "none", color: "inherit" }}
                className="ps-3"
                target="_blank"
              >
                <LinkButton
                  size="medium"
                  label="Export PDF"
                  iconName="export"
                  onClick={() => {}}
                />
              </Link>

              {finding?.customer_jira_issue_key ? (
                <SecondaryButton
                  size="small"
                  label={finding.customer_jira_issue_key}
                  iconName="export"
                  onClick={() => {
                    window
                      .open(
                        `${me?.customer?.jira_details?.url}/browse/${finding.customer_jira_issue_key}`,
                        "_blank"
                      )
                      ?.focus();
                  }}
                />
              ) : !hasAutoSendMapping && hasProjectMapping ? (
                <PushFindingJira
                  customerJiraUrl={me?.customer?.jira_details?.url}
                  jiraIssueKey={finding?.customer_jira_issue_key || undefined}
                  finding={finding}
                  placeholder="Sync with Jira"
                  buttonStyle="secondary"
                />
              ) : null}
            </div>
          )}
        </div>
        {isTrialLimited ? (
          <FindingsTrialLimitation />
        ) : (
          <Flex gap="24px" column={isSmallScreen}>
            {isFetching && !isRefetching ? (
              <Flex
                gap="24px"
                column
                style={{ width: isSmallScreen ? "100%" : "75%" }}
              >
                <TopBoxesSkeleton />
                <MiddleBoxSkeleton />
                <BottomBoxSkeleton />
              </Flex>
            ) : (
              <Flex
                gap="24px"
                column
                style={{ width: isSmallScreen ? "100%" : "75%" }}
              >
                {me?.customer.is_locked ? (
                  <FindingsExpired />
                ) : (
                  <Flex column gap="24px" ref={boxesFlexRef}>
                    <TopBoxes finding={finding} />

                    <MiddleBox
                      finding={finding}
                      setShowAffectedAssetsPane={setShowAffectedAssetsPane}
                      onClickProject={(project) => setShowProject(project)}
                      onClickAsset={(assetId) => setShowAsset(assetId)}
                    />

                    {isSuperuser && <RelatedFindings finding={finding} />}

                    {/* DESCRIPTION , ATTACK DETAILS , MITIGATION */}
                    <BottomBox finding={finding} />
                  </Flex>
                )}
              </Flex>
            )}
            <Box
              style={{
                width: isSmallScreen ? "100%" : "25%",
                transition: "0.4s",
                maxHeight: boxesHeight,
                overflowY: "hidden",
              }}
            >
              <FindingHistory
                finding={finding}
                isAdminMode={searchParams.get("admin-mode") === "true"}
                containerRef={boxesFlexRef}
              />
            </Box>
          </Flex>
        )}
      </div>

      {showAffectedAssetsPane && finding && (
        <AffectedAssetsPane
          finding={finding}
          onClose={() => setShowAffectedAssetsPane(false)}
          onClickAsset={(assetId) => setShowAsset(assetId)}
        />
      )}

      {showAsset ? (
        <AssetPane assetId={showAsset} onClose={() => setShowAsset(0)} />
      ) : null}

      {showProject && (
        <ProjectPane
          onClose={() => setShowProject(undefined)}
          project={showProject}
        />
      )}

      {showImageModal && (
        <ImageModal
          onClose={() => setShowImageModal(false)}
          image={clickedSource}
        />
      )}
    </>
  );
};
