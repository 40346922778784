import { useState } from "react";
import { Flex } from "../../../components/layouts/flex/Flex";
import { HeaderSecondary } from "../../../components/elements/typography/Typography";
import { Finding } from "../../../types/Finding";
import { FindingBadges } from "../findingPane/FindingBadges";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { DateItem } from "../findingPane/items/DateItem";
import { SlaItem } from "../findingPane/items/SlaItem";
import { StatusItem } from "./StatusItemFindingDetails";
import { SeverityItem } from "../findingPane/items/SeverityItem";
import { AssignedToItem } from "../findingPane/items/AssignedToItem";
import { IssuedByItem } from "../findingPane/items/IssuedByItem";
import { DiscoverOnItem } from "../findingPane/items/DiscoverOnItem";
import { useApiProjects } from "../../../hooks/queries/projectsContext";
import { getProject } from "../../../shared/findingsHelper";
import { ImpactItem } from "../findingPane/items/ImpactItem";
import { ExploitabilityItem } from "../findingPane/items/ExploitabilityItem";
import { CVSSItem } from "../findingPane/items/CVSSItem";
import { ScannerItem } from "../findingPane/items/ScannerItem";
import { Box } from "../../../components/elements/box/Box";
import { AffectedProductsList } from "../findingPane/items/AffectedProductsList";
import { AffectedAssetsList } from "../findingPane/items/AffectedAssetsList";
import { AffectedAssetsPane } from "./AffectedAssetsPane";
import { useApiProducts } from "../../../hooks/queries/productsContext";
import { BottomBox } from "./BottomBox";
import { FindingLastSeen } from "../findingPane/items/FindingLastSeen";
import { IconButton } from "../../../components/elements/button/icon/IconButton";
import { FindingAttachmentsDetails } from "./FindingAttachmentsDetails";

type Props = {
  finding: Finding | undefined;
};
const FindingDetailsMobile = (props: Props) => {
  const { finding } = props;
  const { data: products } = useApiProducts();
  const { data: projects } = useApiProjects();
  const project = getProject(finding, projects);

  const [showAffectedAssetsPane, setShowAffectedAssetsPane] = useState(false);

  return (
    <>
      <Flex column w100 gap="16px" style={{ height: "100vh" }}>
        <Flex align="center" gap="8px">
          <IconButton
            iconName="chevronLeft"
            size="small"
            onClick={() => window.history.back()}
          />
          <HeaderSecondary>{finding?.title}</HeaderSecondary>
        </Flex>
        <FindingBadges finding={finding} showTicketBadge />
        <Flex column w100 gap="16px" align="center">
          <Box className="d-flex flex-column gap-24 w-100">
            <Flex column align="center" gap="24px" w100>
              <StatusItem finding={finding} width={"100%"} headerStyle="sub" />
              <SeverityItem
                finding={finding}
                headerStyle="sub"
                width={"100%"}
                noSeparator
              />
              <DateItem finding={finding} headerStyle="sub" width={"100%"} />
              <AssignedToItem finding={finding} />
              <DiscoverOnItem
                project={project}
                width={"100%"}
                isAutomated={!!finding?.is_automated}
              />

              <SlaItem finding={finding} headerStyle="sub" width={"100%"} />
              <IssuedByItem finding={finding} width={"100%"} />
              <ImpactItem finding={finding} />

              <SeparatorHorizontal />

              <Flex align="center" gap="16px" w100>
                <ExploitabilityItem finding={finding} width={"100%"} />
                <CVSSItem finding={finding} width={"100%"} noSeparator />
              </Flex>
              <Flex align="center" gap="16px" w100>
                <FindingLastSeen finding={finding} width={"100%"} />
                <ScannerItem finding={finding} width={"100%"} noSeparator />
              </Flex>
            </Flex>
          </Box>
          <Box className="d-flex flex-column gap-24 w-100">
            <AffectedProductsList finding={finding} products={products} />
            <AffectedAssetsList
              finding={finding}
              setShowAffectedAssetsPane={setShowAffectedAssetsPane}
            />
            <FindingAttachmentsDetails findingId={finding?.id} />
          </Box>
          <BottomBox finding={finding} />
        </Flex>
      </Flex>
      {showAffectedAssetsPane && finding && (
        <AffectedAssetsPane
          finding={finding}
          onClose={() => setShowAffectedAssetsPane(false)}
        />
      )}
    </>
  );
};

export default FindingDetailsMobile;
