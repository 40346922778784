import styled from "styled-components";

export type InputProps = {
  isError?: boolean;
  iconName?: string;
  inverted?: boolean;
  width?: string | number;
  border?: string;
  color?: string;
  initValue?: string;
  saveChangesMode?: boolean;
};

export const InputStyle = styled.input<InputProps>`
  width: ${(props) => props.width || "inherit"}
  height: 40px;
  font-size: 14px;
  light-height: 21px;
  color: ${(props) =>
    props.isError
      ? props.theme.redPrimary
      : props.inverted
        ? props.theme.textWhite
        : props.color || props.theme.textBody};
  border: ${(props) =>
    props.isError
      ? `1px solid ${props.theme.redPrimary}`
      : props.border
        ? props.border
        : `1px solid ${
            props.inverted ? props.theme.white30 : props.theme.black500
          }`};
  border-radius: 0.6em;
  padding: ${(props) => (props.saveChangesMode ? "8px 78px 8px 16px" : "8px 16px")};
  background-color: ${(props) => (props.initValue && props.initValue !== props.value ? props.theme.blue50 : "inherit")};
  transition: 0.2s;
  ${(props) =>
    !!props.iconName &&
    `padding-left: 40px;
    background-size: 14px;
    `}
  &:hover:enabled {
    border-color: ${(props) =>
      props.inverted ? props.theme.white80 : props.theme.primary};
  }
  &:focus {
    outline: none;
    border-color: ${(props) =>
      props.inverted ? props.theme.white100 : props.theme.primary};

    color: ${(props) =>
      props.inverted ? props.theme.white100 : props.theme.textHeader};
  }
  &:disabled {
    background-color: ${(props) =>
      props.inverted ? "inherit" : props.theme.separation};
    opacity: ${(props) => (props.inverted ? 0.5 : 1)};
  }
  &::placeholder {
    color: ${(props) =>
      props.inverted ? props.theme.white80 : props.theme.textSub};
  }


  /* Hide the default arrows in most browsers */
  -moz-appearance: textfield;
  -webkit-appearance: none;
  appearance: none;

  /* Hide spinners in Firefox */
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
